import React, { useContext, useEffect } from "react"
import { BookingForm } from "../../context/context"
import { graphql } from "gatsby"
import ContentArea from "../../dynamic-zone/content-area"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import HeroSection from "../../containers/hero-section-dynamic"
import Instagram from "../../containers/instagram"
import ContactUs from "../../containers/contact-us"
import EuphoriaLogo from "../../images/euphoria-logo.png"
const Euphoria = ({ data, location }) => {
  const siteTitle = data.strapiClub.seo.metaTitle || `Title`
  const club = data.strapiClub
  const { setBookingForm } = useContext(BookingForm)
  useEffect(() => {
    setBookingForm({ open: false, type: "Burra", id: 1 })
  }, [])
  return (
    <Layout
      location={location}
      logo={EuphoriaLogo}
      color="bg-euphoriaBlack-50"
      ctaColor="bg-tealCabanas-50"
      textCtaColor="text-white"
      clubs
      onlyContactUs
    >
      <Seo title="Euphoria Club" />
      <HeroSection
        smallImage={club.backgroundImageMobile}
        image={club.backgroundImageMain}
        wheel
        fullScreen
      />
      <ContentArea
        events={data.allStrapiEvent.edges}
        eventsCarouselBg="#19D3C5"
        eventsCarouselFontColor="#FFFFFF"
        bg="bg-euphoriaBlack-50"
        bgGallery="bg-euphoriaBlack-50"
        color="#19D3C5"
        onlyContactUs
        noBottomMargin
        type="euphoria"
        threeColBg="bg-euphoriaBlack-50"
        textColor="text-tealCustom-50"
        titleColor="text-white"
        cloudColor="text-tealCustom-50"
        cloudBorder="border-tealCustom-50"
        galleries={club.galleries}
        contentTypes={club.pageContent}
        contactColor="bg-euphoriaBlack-50"
        galleryCtaTextColor="text-white"
        galleryCtaBorderColor="border-white"
      />

      <ContactUs
        color="bg-euphoriaBlack-50"
        contactHeaderColor="text-white"
        contactSubHeaderColor="text-tealCustom-50"
        contactContentColor="text-white"
        contactCtaColor="text-white"
        contactCtaBorderColor="border-white"
        onlyContactUs
        iframeSrc={
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2843.7380431171523!2d14.911484315754274!3d44.540995979100856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476249feff4791f7%3A0xccc738747871a8e0!2sEuphoria%20Club!5e0!3m2!1shr!2sba!4v1669142440466!5m2!1shr!2sba"
        }
      />
      {/* <ZrceFun />
      <TwoColumnImages />
      <Restaurants />
      <Cabanas />
      <JetSki /> */}
      {/* <LatestNews /> */}
      {/* <Gallery /> */}
      {/* <Instagram
        bgColor="#1AB5AA"
        type={"mainPage"}
        title={"euphoria_zrcebeach"}
        subscribeColor={"bg-yellowdark-50"}
        // instaNodes={data.allInstaNode.edges}
      /> */}
    </Layout>
  )
}

export default Euphoria

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    strapiClub(strapi_id: { eq: 2 }) {
      id
      clubName
      seo {
        metaTitle
        metaDescription
      }
      galleries {
        id
        title
        images {
          id
          localFile {
            childImageSharp {
              id
              gatsbyImageData(quality: 10)
            }
          }
        }
      }
      backgroundImageMain {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 100)
          }
        }
      }
      backgroundImageMobile {
        localFile {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              formats: WEBP
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
      pageContent {
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_EVENTS {
          id
          sectionId
          strapi_component
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_GALLERY_CLUB {
          id
          strapi_component
          cloudText
          title
          subtitle
        }
      }
    }
    allStrapiEvent {
      edges {
        node {
          slug
          title
          dateTimeFrom(formatString: "DD MMM")
          dateTimeTo(formatString: "DD MMM")
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 20)
              }
            }
          }
        }
      }
    }
    # allInstaNode(filter: { username: { eq: "euphoria_zrcebeach" } }) {
    #   edges {
    #     node {
    #       id
    #       localFile {
    #         id
    #         childImageSharp {
    #           gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
    #         }
    #       }
    #     }
    #   }
    # }
  }
`

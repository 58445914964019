/* This example requires Tailwind CSS v2.0+ */
import React from "react"
// import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function HeroSectionDynamic({
  image,
  smallImage,
  wheel,
  small,
  sectionType,
  fullScreen,
}) {
  // const mainImage = getImage(image.localFile)
  const images = withArtDirection(getImage(image.localFile), [
    {
      media: "(max-width: 768px)",
      image: getImage(smallImage.localFile),
    },
  ])
  return (
    <div className="bg-white">
      <main>
        <div>
          <div
            className={`relative ${
              small
                ? `h-[40vh]`
                : sectionType === "restaurant"
                ? `h-[90vh] lg:h-[100vh]`
                : `h-[90vh]`
            }`}
          >
            <div className="absolute inset-x-0 bottom-0 h-screen bg-gray-100" />
            <div className="mx-auto">
              <div className="relative sm:overflow-hidden ">
                <GatsbyImage
                  // placeholder="tracedSVG"
                  className={`${
                    !fullScreen ? "h-[80vh]" : "h-screen"
                  } lg:h-screen inset-0 w-full`}
                  image={images}
                  objectFit="cover"
                  loading="eager"
                />
              </div>
            </div>
            {wheel && (
              <div className="absolute left-0 right-0 bottom-10 ml-auto mr-auto w-28 flex items-center justify-center">
                <div className="mouse"></div>
              </div>
            )}
          </div>

          {/* Logo cloud */}
        </div>

        {/* More main page content here... */}
      </main>
    </div>
  )
}

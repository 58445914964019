/* eslint-disable react/no-children-prop */
import React, { useContext } from "react"
import { BookingForm } from "../context/context"
import email from "../images/email.png"
import phone from "../images/call.png"
import direction from "../images/direction.png"
import location from "../images/location.png"
import { Link } from "gatsby"
const features = [
  {
    name: "hi@zrcebeach.com",
    icon: email,
  },
  {
    name: "00385 91 112 2241",
    icon: phone,
  },
  {
    name: "Zrce beach, 53291, Novalja",
    icon: direction,
  },
  {
    name: "Get Directions",
    icon: location,
  },
]

const contactDetails = [
  {
    name: "Contact",
    email: {
      underline: false,
      href: "mailto:hi@zrcebeach.com",
      text: "hi@zrcebeach.com",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
          />
        </svg>
      ),
    },
    telephone: {
      underline: false,
      href: "tel:+385911122241",
      text: "00385 91 112 2241",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
          />
        </svg>
      ),
    },
  },
  {
    name: "Location",
    email: {
      underline: false,
      text: "Zrce beach, 53291, Novalja",
      href: "https://goo.gl/maps/YDJPbewWz9jUq9Up6",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
          />
        </svg>
      ),
    },
    telephone: {
      underline: true,
      text: "Get directions",
      href: "https://goo.gl/maps/YDJPbewWz9jUq9Up6",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
          />
        </svg>
      ),
    },
  },
]
export default function ContactUs({
  color,
  contactHeaderColor,
  contactSubHeaderColor,
  contactContentColor,
  contactCtaColor,
  contactCtaBorderColor,
  iframeSrc,
  onlyContactUs,
}) {
  const { bookingForm, setBookingForm } = useContext(BookingForm)
  return (
    <div
      className={`relative pt-10 px-10 lg:px-0 pb-20  lg:pb-44  overflow-hidden font-secondary ${color}`}
      //   id={content.sectionId}
    >
      <div>
        <div
          className="md:mx-auto md:w-11/12 max-w-7xl lg:grid lg:grid-cols-2
         lg:grid-flow-col-dense lg:gap-24"
        >
          <div className="flex flex-col items-start justify-between  sm:px-6 lg:max-w-none  lg:px-0 lg:col-start-1">
            <h2
              className={`text-primary font-primary-bold  text-4xl font-semibold ${contactHeaderColor} lg:pb-12`}
            >
              Get in touch
            </h2>
            <div className="space-y-10 md:grid md:grid-cols-2 md:gap-x-14 md:gap-y-6 md:space-y-0 pb-0">
              <div className="mt-14 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:col-span-2 lg:mt-0">
                {contactDetails.map((item, id) => (
                  <div key={id}>
                    <h3
                      className={`text-2xl mb-4 font-primary-bold ${contactSubHeaderColor} `}
                    >
                      {item.name}
                    </h3>
                    <dl className={`mt-2 text-lg ${contactContentColor}`}>
                      <div className="flex  items-center">
                        <dt className="sr-only">Email</dt>
                        <dd className="pr-2">{item.email.icon}</dd>
                        <a
                          href={item.email.href}
                          className={` ${item.underline && "underline"}`}
                        >
                          {item.email.text}
                        </a>
                      </div>
                      <div className="mt-4 flex items-center">
                        <dt className="sr-only">Phone number</dt>
                        <dd className="pr-2">{item.telephone.icon}</dd>
                        <a
                          href={item.telephone.href}
                          className={`${
                            item.telephone.underline && "underline"
                          }`}
                        >
                          {item.telephone.text}
                        </a>
                      </div>
                    </dl>
                  </div>
                ))}
              </div>
            </div>

            {/* <h2 className="text-primary font-primary-bold  text-xl lg:text-5xl font-semibold text-white">
              Get in touch
            </h2> */}
            <div className="rounded-md mt-14 mb-4 w-full">
              {onlyContactUs ? (
                <Link
                  to="/contact-us"
                  className={` flex lg:inline-block w-full lg:w-[unset] items-center justify-center px-8 py-3 border-2  ${contactCtaBorderColor} ${contactCtaColor} text-sm font-primary-bold transition-all rounded-full hover:text-purplebglight-50 hover:bg-white bg-transparent md:py-4 md:text-sm md:px-10`}
                >
                  Contact Us
                </Link>
              ) : (
                <button
                  onClick={() => setBookingForm({ ...bookingForm, open: true })}
                  className={`flex lg:inline-block w-full lg:w-[unset] items-center justify-center px-8 py-3 border-2  ${contactCtaBorderColor} ${contactCtaColor} text-sm font-primary-bold transition-all rounded-full hover:text-purplebglight-50 hover:bg-white bg-transparent md:py-4 md:text-sm md:px-10`}
                >
                  Reservation
                </button>
              )}
              {/* <button
                onClick={() => setBookingForm({ ...bookingForm, open: true })}
                className={`inline-block w-full lg:w-[unset] items-center justify-center px-8 py-3 border-2  ${contactCtaBorderColor} ${contactCtaColor} text-sm font-primary-bold transition-all rounded-full hover:text-purplebglight-50 hover:bg-white bg-transparent md:py-4 md:text-sm md:px-10`}
              >
                Reservation
              </button> */}
            </div>
          </div>
          {/* <div className={`relative group lg-col-start-2`}>
            <div className="lg:px-0 lg:m-0 lg:relative lg:h-full aspect-auto mx-auto text-right">
              <StaticImage
                placeholder="tracedSVG"
                className="w-full"
                imgClassName="w-full h-full"
                objectFit="contain"
                src="../images/map.png"
                alt=""
              />
            </div>
          </div> */}
          <div className={`relative mt-10 lg:mt-0 group lg-col-start-2`}>
            <iframe
              className="lg:px-0 lg:m-0 lg:relative  aspect-auto mx-auto text-right w-full h-[400px] rounded-2xl border-white border-2"
              src={
                iframeSrc ||
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11374.930159406305!2d14.9133953!3d44.54110864999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476249ffab0182e1%3A0x22e47d9941fc2d53!2sZrce%20Festival%20Beach!5e0!3m2!1shr!2srs!4v1666004519737!5m2!1shr!2srs"
              }
              // style={{ border: 0 }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        {/* {content.ctaButton && (
          <SingleLink
            progId={progId}
            icon={content.ctaButton?.cta_type?.ctaIcon}
            type={
              content.ctaButton?.ctaType || content.ctaButton?.cta_type?.ctaType
            }
            title={
              content.ctaButton?.ctaText ||
              content?.ctaButton?.cta_type?.ctaDefaultText
            }
            key={content.ctaButton?.ctaId}
            url={
              content.ctaButton?.ctaUrl ||
              content.ctaButton?.cta_type?.ctaDefaultUrl
            }
            secondary={
              content.ctaButton?.ctaStyle === "Secondary" ||
              content.ctaButton.cta_type?.ctaDefaultStyle === "Secondary"
            }
          />
        )} */}
      </div>
    </div>
  )
}
